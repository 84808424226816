// import '@types/facebook-js-sdk'
import { env } from '@/shared/config'
import { loadScript } from '@/shared/lib/loadScript'
import type { IFacebookPixel } from './facebook.types'

const INTERVAL_WAIT_LOADING = 100

export class FacebookClient {
  public loaded: boolean = false
  public isInit: boolean = false
  public instance?: IFacebookPixel

  public async init() {
    // @ts-ignore // TODO доробити
    window.fbAsyncInit = () => (this.loaded = true)
    await loadScript('https://connect.facebook.net/es_LA/sdk.js')
    if (!this.loaded) {
      await new Promise((resolve) => {
        const intervalId = setInterval(() => {
          if (!this.loaded) return
          clearInterval(intervalId)
          resolve(true)
        }, INTERVAL_WAIT_LOADING)
      })
    }

    // @ts-ignore // TODO доробити
    const fbClient = window.FB

    fbClient.init({
      appId: env.FACEBOOK_PIXEL_ID,
      autoLogAppEvents: true,
      xfbml: false,
      version: 'v16.0',
    })

    this.instance = fbClient

    this.isInit = true
  }
}
