import Hotjar from '@hotjar/browser'
import { env } from '@/shared/config'

export class HotjarClient {
  public isInit: boolean = false
  public instance = Hotjar

  public init() {
    Hotjar.init(env.HOTJAR_ID, env.HOTJAR_VERSION)
    this.isInit = true
  }
}
