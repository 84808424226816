import type { App, Plugin } from 'vue'
import { env } from '@/shared/config'
import { analytics } from '@/shared/plugins/analytics'
// import { appLogEventPost, appFbdataPost } from '@/shared/api'
// import { cookies } from '@/shared/lib/cookies'
import type {
  IFacebookPlugin,
  FacebookInitData,
  FacebookEvent,
  FacebookEventParameters,
  FacebookEventOptions,
} from './facebook.types'
import { FacebookKey } from './facebook.config'
import { FacebookClient } from './facebookClient.service'
import { FacebookAnalytics } from './facebookAnalytics.service'

// const PARAM_FACEBOOK_FBP = '_fbp'
// const PARAM_FACEBOOK_FBC = '_fbc'

export const facebookPlugin: Plugin = {
  install(app: App) {
    const facebookClient = new FacebookClient()
    const facebookAnalytics = new FacebookAnalytics(facebookClient)
    analytics.adapterSet(facebookAnalytics)

    const api: IFacebookPlugin = {
      event(
        event: FacebookEvent,
        data: FacebookEventParameters,
        options?: FacebookEventOptions
      ) {
        if (!facebookClient.isInit || !facebookClient.instance) return
        // const access = this._context.$cookiesAccess.checkAccess('analytics')
        // if (!access) return
        if (!env.IS_PROD)
          console.log('Facebook event: %s', event, data, options)
        if (!env.IS_PROD) data.test_event_code = env.FACEBOOK_TEST_CODE

        if (options) {
          facebookClient.instance.fbq('track', event, data, options)
        } else {
          facebookClient.instance.track(event, data)
        }
        try {
          // appLogEventPost(event, {
          //   ...data,
          //   ...options,
          // })
        } catch (err) {
          console.error(err)
        }
      },
      // https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching/
      eventInit(data: FacebookInitData) {
        if (!facebookClient.isInit || !facebookClient.instance) return
        facebookClient.instance.fbq('init', env.FACEBOOK_PIXEL_ID, data)
      },
      sendFbcFba() {
        // if (!facebookClient.isInit) return
        // appFbdataPost(
        //   cookies.get(PARAM_FACEBOOK_FBP),
        //   cookies.get(PARAM_FACEBOOK_FBC)
        // )
      },
    }
    app.config.globalProperties.$facebook = api
    app.provide(FacebookKey, api)
  },
}
