import type { IAnalyticsAdapter } from '@/shared/plugins/analytics'
import type { FacebookClient } from './facebookClient.service'

export class FacebookAnalytics implements IAnalyticsAdapter {
  protected _userId?: string
  public windowId: string = ''
  public sessionId: string = ''
  public deviceId: string = ''
  public showLog: boolean = false

  constructor(protected client: FacebookClient) {}

  public async init() {
    await this.client.init()
    return Promise.resolve()
  }

  public userId() {}

  public userProps() {}

  public event() {}

  public login() {}

  public signUp() {}
}
