import type {
  IAnalyticsAdapter,
  AnalyticUserProps,
  AnalyticEventData,
  AnalyticEventOptions,
  AnalyticAuthMethod,
} from '@/shared/plugins/analytics'
import type { GtagClient } from './gtagClient.service'

export class GoogleAnalytics implements IAnalyticsAdapter {
  protected _windowSeq: number = 0
  protected _userId?: string
  public windowId: string = ''
  public sessionId: string = ''
  public deviceId: string = ''
  public showLog: boolean = false

  constructor(protected client: GtagClient) {}

  public async init() {
    await this.client.init()
    this.client.instance('set', 'user_properties', {
      deviceId: this.deviceId,
      sessionId: this.sessionId,
      windowId: this.windowId,
    })
  }

  public userId(userId: string) {
    this._userId = userId
    if (!this.client.isInit) return
    this.client.instance('set', 'user_properties', { userId })
  }

  public userProps(props: AnalyticUserProps) {
    if (!this.client.isInit) return
    this.client.instance('set', 'user_properties', props)
  }

  public event(
    event: string,
    props?: AnalyticEventData,
    options?: AnalyticEventOptions
  ): void {
    if (!this.client.isInit || options?.gaDisabled) return
    const sendProps = Object.assign({}, props, {
      window: this.windowId,
      windowSeq: this._windowSeq++,
    })
    if (this.showLog) {
      console.log('GoogleAnalytics event: %s', event, sendProps)
    }
    this.client.instance('event', name, sendProps)
  }

  public login(method: AnalyticAuthMethod) {
    if (!this.client.isInit) return
    this.client.instance('login', { method })
  }

  public signUp(method: AnalyticAuthMethod) {
    if (!this.client.isInit) return
    this.client.instance('sign_up', { method })
  }
}
