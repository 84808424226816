import { reactive } from 'vue'

import type {
  ToasterToast,
  ToasterToastTypes,
  ToasterCallerParams,
  IToasterService,
  IToasterServiceMobile,
  IToasterServiceDesktop,
} from '../types'

import { BASE_TOAST_TIMEOUT, BASE_TOAST_DELAY } from '../config'

/**
 * @class Toaster
 * @abstract
 * @implements {IToasterService}
 * @description Abstract class representing the base toaster service.
 */
abstract class Toaster implements IToasterService {
  /**
   * @property {ToasterToast[]} toasts
   * @description An array of reactive toast objects being displayed.
   */
  public toasts: ToasterToast[] = reactive([])

  /**
   * @private
   * @method setTimeoutForToastAndThenDelete
   * @param {symbol} id
   * @returns {NodeJS.Timeout}
   * @description Set a timeout for the toast with the given ID and delete it when the timeout is reached.
   * @example
   * const timeoutId = this.setTimeoutForToastAndThenDelete(toast.id)
   */
  public setTimeoutForToastAndThenDelete(id: symbol): NodeJS.Timeout {
    const timeoutId = setTimeout(() => {
      this.removeToastFromList(id)
      clearTimeout(timeoutId)
    }, BASE_TOAST_TIMEOUT)

    return timeoutId
  }

  /**
   * @private
   * @method getToastItemIndex
   * @param {symbol} id
   * @returns {number}
   * @description Get the index of the toast with the given ID in the toasts array.
   * @example
   * const index = this.getToastItemIndex(toast.id)
   */
  public getToastItemIndex(id: symbol): number {
    return this.toasts.findIndex((toast) => toast.id === id)
  }

  /**
   * @private
   * @method getToastItem
   * @param {symbol} id
   * @returns {ToasterToast | undefined}
   * @description Get the toast object with the given ID from the toasts array.
   * @example
   * const toast = this.getToastItem(toast.id)
   */
  public getToastItem(id: symbol): ToasterToast | undefined {
    return this.toasts.find((toast) => toast.id === id)
  }

  /**
   * @private
   * @method removeToastFromList
   * @param {symbol} id
   * @returns {boolean}
   * @description Remove the toast with the given ID from the toasts array.
   * @example
   * const removed = this.removeToastFromList(toast.id)
   */
  public removeToastFromList(id: symbol): boolean {
    const toastId = this.getToastItemIndex(id)
    if (toastId === -1) return false
    this.toasts.splice(toastId, 1)
    return true
  }

  /**
   * @public
   * @method pushToast
   * @param {ToasterToast} toast
   * @description Push a new toast object to the toasts array and set the timeout for auto-deletion.
   * @example
   * this.pushToast(toast)
   */
  public pushToast(toast: ToasterToast): void {
    toast.timeoutId = this.setTimeoutForToastAndThenDelete(toast.id)
    this.toasts.unshift(toast)
  }

  /**
   * @public
   * @method deleteToast
   * @param {symbol} id
   * @returns {boolean}
   * @description Delete a toast with the given ID from the toasts array and clear its timeout.
   * @example
   * this.deleteToast(toast.id)
   */
  public deleteToast(id: symbol): boolean {
    const toast = this.getToastItem(id)
    if (!toast) return false
    if (toast.timeoutId) clearTimeout(toast.timeoutId)
    return this.removeToastFromList(id)
  }

  /**
   * @public
   * @method stopToasterTimer
   * @param {symbol} toastId
   * @description Stop the timer for the toast with the given ID.
   * @example
   * this.stopToasterTimer(toast.id)
   */
  public stopToasterTimer(toastId: symbol): void {
    const toast = this.getToastItem(toastId)
    if (!toast) return
    if (toast.timeoutId) clearTimeout(toast.timeoutId)
  }

  /**
   * @public
   * @method runToasterTimer
   * @param {symbol} toastId
   * @description Restart the timer for the toast with the given ID.
   * @example
   * this.runToasterTimer(toast.id)
   */
  public runToasterTimer(toastId: symbol): void {
    const toast = this.getToastItem(toastId)
    if (!toast) return
    toast.timeoutId = this.setTimeoutForToastAndThenDelete(toastId)
  }

  /**
   * @public
   * @method getUniqueId
   * @returns {symbol}
   * @description Generate and return a unique symbol ID.
   * @example
   * const uniqueId = this.getUniqueId()
   */
  public getUniqueId(): symbol {
    return Symbol()
  }

  /**
   * @public
   * @method prepareToasterToastObject
   * @param {ToasterToastTypes} type
   * @returns {ToasterToast}
   * @description Prepare a new ToasterToast object with the given type and return it.
   * @example
   * const toast = this.prepareToasterToastObject('error')
   */
  public prepareToasterToastObject(type: ToasterToastTypes): ToasterToast {
    return {
      message: '',
      title: '',
      type,
      icon: false,
      avatar: '',
      date: Date.now(),
      id: this.getUniqueId(),
      timeoutId: null,
      callback: undefined,
    }
  }
}

/**
 * @class ToasterDesktop
 * @extends {Toaster}
 * @implements {IToasterServiceDesktop}
 * @description Class representing the desktop-specific toaster service.
 */
export class ToasterDesktop extends Toaster implements IToasterServiceDesktop {
  /**
   * @public
   * @method error
   * @param {ToasterCallerParams | string} params
   * @description Display an error toast with the provided parameters or a string as the title.
   * @example
   * this.error({title: 'Error', message: 'Something went wrong'})
   * this.error('Error')
   */
  public error(params: ToasterCallerParams | string): void {
    const toaster = this.prepareToasterToastObject('error')

    if (typeof params !== 'string') {
      Object.assign(toaster, params)
    } else toaster.title = params

    this.pushToast(toaster)
  }

  /**
   * @public
   * @method info
   * @param {ToasterCallerParams | string} params
   * @description Display an info toast with the provided parameters or a string as the title.
   * @example
   * this.info({title: 'Info', message: 'Here is some information'})
   * this.info('Info')
   */
  public info(params: ToasterCallerParams | string): void {
    const toaster = this.prepareToasterToastObject('info')
    if (typeof params !== 'string') {
      Object.assign(toaster, params)
    } else toaster.title = params
    this.pushToast(toaster)
  }
}

/**
 * @class ToasterMobile
 * @extends {Toaster}
 * @implements {IToasterServiceMobile}
 * @description Class representing the mobile-specific toaster service.
 */
export class ToasterMobile extends Toaster implements IToasterServiceMobile {
  /**
   * @public
   * @type {boolean}
   * @description Flag indicating whether the queue of toasts is being processed.
   */
  public isQueueBusy: boolean = false

  /**
   * @public
   * @type {ToasterToast[]}
   * @description Array of toasts waiting in the queue to be displayed.
   */
  public waitersQueue: ToasterToast[] = []

  /**
   * @public
   * @method error
   * @param {ToasterCallerParams | string} params
   * @description Display an error toast with the provided parameters or a string as the title.
   * @example
   * this.error({title: 'Error', message: 'Something went wrong'})
   * this.error('Error')
   */
  public error(params: ToasterCallerParams | string): void {
    const toaster = this.prepareToasterToastObject('error')

    if (typeof params !== 'string') {
      Object.assign(toaster, params)
    } else toaster.title = params

    this.toasts.length ? this.pushToQueue(toaster) : this.pushToast(toaster)
  }

  /**
   * @public
   * @method info
   * @param {ToasterCallerParams | string} params
   * @description Display an info toast with the provided parameters or a string as the title.
   * @example
   * this.info({title: 'Info', message: 'Here is some information'})
   * this.info('Info')
   */
  public info(params: ToasterCallerParams | string): void {
    const toaster = this.prepareToasterToastObject('info')

    if (typeof params !== 'string') {
      Object.assign(toaster, params)
    } else toaster.title = params

    this.toasts.length ? this.pushToQueue(toaster) : this.pushToast(toaster)
  }

  /**
   * @public
   * @method pushToQueue
   * @param {ToasterToast} toast
   * @description Add a new toast to the waiters queue and resolve the queue if it's not busy.
   * @example
   * this.pushToQueue(toaster)
   */
  public pushToQueue(toast: ToasterToast): void {
    this.waitersQueue.push(toast)
    this.resolveQueue()
  }

  /**
   * @public
   * @method resolveQueue
   * @description Process the queue of toasts and display them with a delay if the queue is not busy.
   * @example
   * this.resolveQueue()
   */
  public resolveQueue(): void {
    if (!this.isQueueBusy && this.waitersQueue.length) {
      this.isQueueBusy = true
      const timeout = setTimeout(() => {
        const item = this.waitersQueue.shift()
        if (item) this.pushToast(item)
        clearTimeout(timeout)
        this.isQueueBusy = false
        this.resolveQueue()
      }, BASE_TOAST_DELAY)
    }
  }
}
