export const env = Object.freeze({
  HOST_NAME: 'hiti.live',
  PROJECT_NAME: import.meta.env.PKG.PROJECT_NAME,
  VERSION: import.meta.env.PKG.VERSION || '0.0.0',
  NODE_ENV: import.meta.env.VITE_NODE_ENV,
  IS_DEV: import.meta.env.VITE_NODE_ENV !== 'production',
  IS_PROD: import.meta.env.VITE_NODE_ENV === 'production',

  AUTH_URL: import.meta.env.VITE_AUTH_URL,
  APP_URL: import.meta.env.VITE_APP_URL,
  TRACK_URL: import.meta.env.VITE_TRACK_URL,
  SOCKET_URL: import.meta.env.VITE_SOCKET_URL || '',
  LOG_URL: import.meta.env.VITE_LOG_URL || '',

  VERSION_BACKEND: import.meta.env.VITE_VERSION_BACKEND || '0',
  APPLICATION: import.meta.env.VITE_APPLICATION,
  WEBSOCKET_APPLICATION: import.meta.env.VITE_WEBSOCKET_APPLICATION || '',
  UPLOAD_APP: import.meta.env.VITE_UPLOAD_APP,
  UPLOAD_APP_NAME: import.meta.env.VITE_UPLOAD_APP_NAME,

  GOOGLE_MEASUREMENT_ID: import.meta.env.VITE_GOOGLE_MEASUREMENT_ID,
  GOOGLE_ANALYTICS_ID: import.meta.env.VITE_GOOGLE_ANALYTICS_ID,
  GOOGLE_OPTIMIZE_ID: import.meta.env.VITE_GOOGLE_OPTIMIZE_ID,
  GOOGLE_OAUTH_CLIENT_ID: import.meta.env.VITE_GOOGLE_OAUTH_CLIENT_ID,
  GOOGLE_TRANSLATE_KEY: import.meta.env.VITE_GOOGLE_TRANSLATE_KEY,

  SENTRY_DNS: import.meta.env.VITE_SENTRY_DNS || '',
  SENTRY_AUTH_TOKEN: import.meta.env.VITE_SENTRY_AUTH_TOKEN || '',
  SENTRY_ORG: import.meta.env.VITE_SENTRY_ORG || '',
  // SENTRY_PROJECT: import.meta.env.PROJECT_NAME,

  FACEBOOK_PIXEL_ID: import.meta.env.VITE_FACEBOOK_PIXEL_ID,
  FACEBOOK_TEST_CODE: import.meta.env.VITE_FACEBOOK_TEST_CODE,

  AMPLITUDE_API_KEY: import.meta.env.VITE_AMPLITUDE_API_KEY,

  HOTJAR_ID: import.meta.env.VITE_HOTJAR_ID,
  HOTJAR_VERSION: import.meta.env.VITE_HOTJAR_VERSION,

  ELASTIC_SERVER_URL: import.meta.env.VITE_ELASTIC_SERVER_URL || '',
})
