import type { App, Plugin } from 'vue'
import { analytics } from '@/shared/plugins/analytics'
import { AmplitudeKey } from './amplitude.config'
import type { IAmplitudePlugin } from './amplitude.types'
import { AmplitudeClient } from './amplitudeClient.service'
import { AmplitudeAnalytics } from './amplitudeAnalytics.service'

export const amplitudePlugin: Plugin = {
  install(app: App) {
    const amplitudeClient = new AmplitudeClient()
    const amplitudeAnalytics = new AmplitudeAnalytics(amplitudeClient)
    analytics.adapterSet(amplitudeAnalytics)

    const api: IAmplitudePlugin = {
      revenue(
        price: number,
        productId: string = 'mili_web',
        quantity: number = 1
      ): void {
        if (!amplitudeClient.isInit) return
        const instance = amplitudeClient.instance
        const revenue = new instance.Revenue()
          .setProductId(productId)
          .setQuantity(quantity)
          .setRevenueType('purchase')
          .setPrice(price)
        instance.logRevenueV2(revenue)
      },
    }
    app.config.globalProperties.$amplitude = api
    app.provide(AmplitudeKey, api)
  },
}
