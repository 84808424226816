import { env } from '@/shared/config'
import { deviceIdGet } from '@/shared/lib/deviceId'
import { sessionIdGet } from '@/shared/lib/sessionId'
import { windowIdGet } from '@/shared/lib/windowId'
import { AnalyticsService } from './analytics.service'

export const analytics = new AnalyticsService(
  windowIdGet(),
  sessionIdGet(),
  deviceIdGet(),
  !env.IS_PROD
)
