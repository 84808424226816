import { createRouter, createWebHistory } from 'vue-router'
import { ROUTE_NAV } from '@/shared/config'
const CabHeader = () => import('@/widgets/cab-header/ui/CabHeader.vue')

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: ROUTE_NAV.index,
      name: 'index',
      component: () => import('@/pages/IndexPage.vue'),
    },
    {
      path: ROUTE_NAV.banned,
      name: 'banned',
      component: () => import('@/pages/Banned.vue'),
    },
    {
      path: ROUTE_NAV.doubleConnection,
      name: 'doubleConnection',
      component: () => import('@/pages/DoubleConnection.vue'),
    },
    {
      path: ROUTE_NAV.cookiesPolicy,
      name: 'cookiesPolicy',
      component: () => import('@/pages/CookiePolicy.vue'),
    },
    {
      path: ROUTE_NAV.privacyPolicy,
      name: 'privacyPolicy',
      component: () => import('@/pages/PrivacyPolicy.vue'),
    },
    {
      path: ROUTE_NAV.purchaseTerms,
      name: 'purchaseTerms',
      component: () => import('@/pages/PurchaseTerms.vue'),
    },
    {
      path: ROUTE_NAV.subscriptionTerms,
      name: 'subscriptionTerms',
      component: () => import('@/pages/SubscriptionTerms.vue'),
    },
    {
      path: ROUTE_NAV.termsAndConditions,
      name: 'termsAndConditions',
      component: () => import('@/pages/TermsAndConditions.vue'),
    },
    {
      path: ROUTE_NAV.login,
      name: 'authLogin',
      component: () => import('@/pages/auth/LoginPage.vue'),
    },
    {
      path: '/auth',
      name: 'auth',
      component: () => import('@/pages/auth/AuthPage.vue'),
      redirect: () => ({ path: ROUTE_NAV.index }),
      children: [
        {
          path: ROUTE_NAV.registrEmail,
          name: 'registrEmail',
          meta: {
            public: true,
          },
          component: () => import('@/pages/auth/RegistrEmailPage.vue'),
        },
        {
          path: ROUTE_NAV.registrName,
          name: 'registrName',
          component: () => import('@/pages/auth/RegistrNamePage.vue'),
        },
        {
          path: ROUTE_NAV.registrBefore,
          name: 'registrBefore',
          component: () => import('@/pages/auth/RegistrBeforePage.vue'),
        },
        {
          path: ROUTE_NAV.registrSafe,
          name: 'registrSafe',
          component: () => import('@/pages/auth/RegistrSafePage.vue'),
        },
        {
          path: ROUTE_NAV.registrSelfiePassport,
          name: 'registrSelfiePassport',
          component: () => import('@/pages/auth/RegistrSelfiePassportPage.vue'),
        },
        {
          path: ROUTE_NAV.registrPermissions,
          name: 'registrPermissions',
          component: () => import('@/pages/auth/RegistrPermissionsPage.vue'),
        },
        {
          path: ROUTE_NAV.registrSelfie,
          name: 'registrSelfie',
          component: () => import('@/pages/auth/RegistrSelfiePage.vue'),
        },
        {
          path: ROUTE_NAV.registrPassport,
          name: 'registrPassport',
          component: () => import('@/pages/auth/RegistrPassportPage.vue'),
        },
        {
          path: ROUTE_NAV.registrBirthday,
          name: 'registrBirthday',
          component: () => import('@/pages/auth/RegistrBirthdayPage.vue'),
        },
        {
          path: ROUTE_NAV.registrPhotos,
          name: 'registrPhotos',
          component: () => import('@/pages/auth/RegistrPhotosPage.vue'),
        },
        {
          path: ROUTE_NAV.registrStories,
          name: 'registrStories',
          component: () => import('@/pages/auth/RegistrStoriesPage.vue'),
        },
        {
          path: ROUTE_NAV.registrRules,
          name: 'registrRules',
          component: () => import('@/pages/auth/RegistrRulesPage.vue'),
        },
        {
          path: ROUTE_NAV.forgotPassword,
          name: 'forgotPassword',
          component: () => import('@/pages/auth/ForgotPasswordPage.vue'),
        },
        {
          path: ROUTE_NAV.recoveryPassword,
          name: 'createNewPassword',
          component: () => import('@/pages/auth/RecoveryPasswordPage.vue'),
        },
      ],
    },
    {
      path: '/cab',
      name: 'cab',
      component: () => import('@/pages/cab/CabPage.vue'),
      redirect: () => ({ path: ROUTE_NAV.cabVideoCall }),
      children: [
        {
          path: ROUTE_NAV.cabStories,
          name: 'stories',
          components: {
            header: CabHeader,
            default: () => import('@/pages/cab/StoriesPage.vue'),
          },
        },
        {
          path: ROUTE_NAV.cabReferral,
          name: 'referral',
          components: {
            header: CabHeader,
            default: () => import('@/pages/cab/ReferralPage.vue'),
          },
        },
        {
          path: ROUTE_NAV.cabReferralDetails,
          name: 'referralDetails',
          components: {
            header: CabHeader,
            default: () => import('@/pages/cab/ReferralDetailsPage.vue'),
          },
        },
        {
          path: ROUTE_NAV.cabReverification,
          name: 'storiesReverification',
          components: {
            default: () => import('@/pages/cab/ProfileReverificationPage.vue'),
          },
        },
        {
          path: ROUTE_NAV.cabStoriesCreate,
          name: 'storiesCreate',
          components: {
            default: () => import('@/pages/cab/StoriesCreatePage.vue'),
          },
        },
        {
          path: ROUTE_NAV.cabStory,
          name: 'story',
          components: {
            default: () => import('@/pages/cab/StoryPage.vue'),
          },
        },
        {
          path: ROUTE_NAV.cabVideoCall,
          name: 'cabVideoChat',
          components: {
            header: CabHeader,
            default: () => import('@/pages/cab/VideoCallPage.vue'),
          },
        },
        {
          path: ROUTE_NAV.cabPrivateCall,
          name: 'cabPrivateCall',
          components: {
            header: CabHeader,
            default: () => import('@/pages/cab/PrivateCallPage.vue'),
          },
        },
        {
          path: ROUTE_NAV.cabCallHistory,
          name: 'cabCallHistory',
          components: {
            header: CabHeader,
            default: () => import('@/pages/cab/CallHistoryPage.vue'),
          },
        },
        {
          path: ROUTE_NAV.cabMessages,
          name: 'cabMessages',
          components: {
            header: CabHeader,
            default: () => import('@/pages/cab/MessagesPage.vue'),
          },
        },
        {
          path: ROUTE_NAV.cabProfile,
          name: 'cabProfile',
          components: {
            header: CabHeader,
            default: () => import('@/pages/cab/ProfilePage.vue'),
          },
        },
        {
          path: ROUTE_NAV.cabProfileEdit,
          name: 'cabProfileEdit',
          components: {
            header: CabHeader,
            default: () => import('@/pages/cab/ProfileEditPage.vue'),
          },
        },
        {
          path: ROUTE_NAV.cabCoinsHistory,
          name: 'cabCoinsHistory',
          components: {
            header: CabHeader,
            default: () => import('@/pages/cab/CoinsHistoryPage.vue'),
          },
        },
        {
          path: ROUTE_NAV.cabWithdrawal,
          name: 'cabWithdrawal',
          components: {
            header: CabHeader,
            default: () => import('@/pages/cab/WithdrawalPage.vue'),
          },
        },
        {
          path: ROUTE_NAV.cabWithdrawalHistory,
          name: 'cabWithdrawalHistory',
          components: {
            header: CabHeader,
            default: () => import('@/pages/cab/WithdrawalHistoryPage.vue'),
          },
        },
        // {
        //   path: ROUTE_NAV.cabRateMatrix,
        //   name: 'cabRateMatrix',
        //   components: {
        //     header: CabHeader,
        //     default: () => import('@/pages/cab/RatePage.vue'),
        //   },
        // },
        // {
        //   path: ROUTE_NAV.cabRateStatistics,
        //   name: 'cabRateStatistics',
        //   components: {
        //     header: CabHeader,
        //     default: () => import('@/pages/cab/RateStatisticsPage.vue'),
        //   },
        // },
        {
          path: ROUTE_NAV.cabReModeration,
          name: 'cabReModeration',
          components: {
            default: () => import('@/pages/cab/ProfileRemoderationPage.vue'),
          },
        },
        {
          path: ROUTE_NAV.cabWeeklyTopStreamer,
          name: 'cabWeeklyTopStreamer',
          components: {
            default: () => import('@/pages/cab/WeeklyTopStreamerPage.vue'),
          },
        },
      ],
    },
  ],
})

export default router
