import type {
  IAnalyticsAdapter,
  AnalyticUserProps,
  AnalyticEventData,
  AnalyticEventOptions,
} from '@/shared/plugins/analytics'
import type { HotjarClient } from './hotjarClient.service'

export class HotjarAnalytics implements IAnalyticsAdapter {
  protected _userId?: string
  public windowId: string = ''
  public sessionId: string = ''
  public deviceId: string = ''
  public showLog: boolean = false

  constructor(
    protected client: HotjarClient,
    protected initCallback: Function
  ) {}

  public async init() {
    this.client.init()
    this.client.instance.identify(this._userId || '', {
      deviceId: this.deviceId,
    })
    this.initCallback()
    return Promise.resolve()
  }

  public userId(userId: string) {
    this._userId = userId
    if (!this.client.isInit) return
    this.client.instance.identify(userId, {
      windowId: this.windowId,
      sessionId: this.sessionId,
      deviceId: this.deviceId,
    })
  }

  public userProps(props: AnalyticUserProps) {
    if (!this.client.isInit) return
    this.client.instance.identify(this._userId || '', props)
  }

  public event(
    event: string,
    props?: AnalyticEventData,
    options?: AnalyticEventOptions
  ): void {
    if (!this.client.isInit || options?.amplitudeDisabled) return
    if (this.showLog) console.log('Hotjar event: %s', event)
    this.client.instance.event(event)
  }

  public login() {}

  public signUp() {}
}
