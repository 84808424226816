import type { IEventBus } from '@/shared/plugins/eventBus'
import type { IModalPlugin, ModalName, ModalAction } from './modal.types'

/**
 * @constant
 * @description Modal event bus context identifier
 */
const EVENT_BUS_CONTEXT = 'modal'

/**
 * @constant
 * @description Modal event bus target for all modals
 */
const EVENT_BUS_TARGET_ALL = 'all'

/**
 * @constant
 * @description Modal event bus command for opening modals
 */
const EVENT_BUS_COMMAND_OPEN = 'open'

/**
 * @constant
 * @description Modal event bus command for closing modals
 */
const EVENT_BUS_COMMAND_CLOSE = 'close'

/**
 * @constant
 * @description Modal event bus command for confirming modals
 */
const EVENT_BUS_COMMAND_CONFIRM = 'confirm'

/**
 * @constant
 * @description Modal event bus command for canceling modals
 */
const EVENT_BUS_COMMAND_CANCEL = 'cancel'

/**
 * @class
 * @implements {IModalPlugin}
 * @description Modal service that allows controlling and listening to modal actions using an event bus
 */
export class ModalService implements IModalPlugin {
  /**
   * @constructor
   * @param {IEventBus} eventBus
   */
  constructor(public readonly eventBus: IEventBus) {}

  /**
   * @private
   * @template P
   * @param {ModalName} name
   * @param {ModalAction} action
   * @param {P} [payload]
   * @description Emit a modal event
   */
  private emit<P>(name: ModalName, action: ModalAction, payload?: P) {
    const eventName = this.eventBus.nameGenerate(
      EVENT_BUS_CONTEXT,
      name,
      action
    )
    this.eventBus.emit<P | undefined>(eventName, payload)
  }

  /**
   * @private
   * @template P
   * @param {ModalName} name
   * @param {ModalAction} action
   * @param {(payload?: P) => void} callback
   * @description Listen for a modal event
   */
  private on<P>(
    name: ModalName,
    action: ModalAction,
    callback: (payload?: P) => void
  ) {
    const eventName = this.eventBus.nameGenerate(
      EVENT_BUS_CONTEXT,
      name,
      action
    )
    this.eventBus.on(eventName, (payload?: P) => callback(payload))
  }

  public open<P>(name: ModalName, payload?: P): void {
    this.emit<P>(name, EVENT_BUS_COMMAND_OPEN, payload)
  }

  public close<P>(name: ModalName, payload?: P): void {
    this.emit<P>(name, EVENT_BUS_COMMAND_CLOSE, payload)
  }

  public confirm<P>(name: ModalName, payload?: P): void {
    this.emit<P>(name, EVENT_BUS_COMMAND_CONFIRM, payload)
  }

  public cancel<P>(name: ModalName, payload?: P): void {
    this.emit<P>(name, EVENT_BUS_COMMAND_CANCEL, payload)
  }

  public action<P>(name: ModalName, action: ModalAction, payload?: P): void {
    this.emit<P>(name, action, payload)
  }

  public onOpen<P>(name: ModalName, callback: (payload?: P) => void): void {
    this.on<P>(name, EVENT_BUS_COMMAND_OPEN, callback)
  }

  public onClose<P>(name: ModalName, callback: (payload?: P) => void): void {
    this.on<P>(name, EVENT_BUS_COMMAND_CLOSE, callback)
  }

  public onConfirm<P>(name: ModalName, callback: (payload?: P) => void): void {
    this.on<P>(name, EVENT_BUS_COMMAND_CONFIRM, callback)
  }

  public onCancel<P>(name: ModalName, callback: (payload?: P) => void): void {
    this.on<P>(name, EVENT_BUS_COMMAND_CANCEL, callback)
  }

  public onAction<P>(
    name: ModalName,
    action: ModalAction,
    callback: (payload?: P) => void
  ): void {
    this.on<P>(name, action, callback)
  }

  public closeAll(): void {
    this.emit<undefined>(EVENT_BUS_TARGET_ALL, EVENT_BUS_COMMAND_CLOSE)
  }

  public onCloseAll(callback: () => void): void {
    this.on<undefined>(EVENT_BUS_TARGET_ALL, EVENT_BUS_COMMAND_CLOSE, callback)
  }

  public offCloseAll(callback: () => void): void {
    const eventName = this.eventBus.nameGenerate(
      EVENT_BUS_CONTEXT,
      EVENT_BUS_TARGET_ALL,
      EVENT_BUS_COMMAND_CLOSE
    )
    this.eventBus.off(eventName, callback)
  }

  public off(name: ModalName, action: ModalAction): void {
    const eventName = this.eventBus.nameGenerate(
      EVENT_BUS_CONTEXT,
      name,
      action
    )
    this.eventBus.off(eventName)
  }
}
