import App from './app/App.vue'
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { sentryPlugin } from '@/shared/plugins/sentry'
import { i18nPlugin } from '@/shared/plugins/i18n'
import { api } from '@/shared/plugins/api'
import { amplitudePlugin } from '@/shared/plugins/amplitude'
import { facebookPlugin } from '@/shared/plugins/facebook'
import { hotjarPlugin } from '@/shared/plugins/hotjar'
import { gtagPlugin } from '@/shared/plugins/gtag'
import { analyticsPlugin } from '@/shared/plugins/analytics'
import { toasterPlugin } from '@/shared/plugins/toaster'
import { modalPlugin } from '@/shared/plugins/modal'
import 'webrtc-adapter'
import router from './app/router'
import './assets/scss/styles.scss'
import './assets/tailwind.css'
import './assets/animation.css'

const app = createApp(App as any)

app.use(router)
app.use(createPinia())
sentryPlugin(app, router)
app.use(i18nPlugin())
app.use(api.apiPlugin)
app.use(amplitudePlugin)
app.use(facebookPlugin)
app.use(hotjarPlugin, { router })
app.use(gtagPlugin)
app.use(analyticsPlugin)
app.use(modalPlugin)
app.use(toasterPlugin)

app.mount('#app')
