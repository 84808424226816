import type { AxiosRequestConfig } from 'axios'
import { axiosClient } from './axiosClient.service'
import { env } from '@/shared/config'
import { deviceIdGet } from '@/shared/lib/deviceId'
import { languageBrowser } from '@/shared/lib/languageBrowser'

import type { Plugin } from 'vue'

export const apiPlugin: Plugin = {
  install() {
    axiosClient.defaults.headers['X-Application'] = env.APPLICATION
    axiosClient.defaults.headers['X-Version-Backend'] = env.VERSION_BACKEND
    axiosClient.interceptors.request.use((config: AxiosRequestConfig) => {
      if (!config.headers) config.headers = {}
      config.headers['X-Device-Id'] = deviceIdGet()
      config.headers.Locale = languageBrowser()
      return config
    })
  },
}
