import type { App, Plugin } from 'vue'
import { ModalKey } from './modal.config'
import { modal } from './modal.state'

/**
 * @description A Vue plugin that provides the modal singleton for managing
 * modals throughout the application. The plugin installs the modal instance on
 * the global properties of the Vue app and provides it using the ModalKey.
 * @type {Plugin}
 * @example
 * // In main.ts, where the Vue app is created
 * import { createApp } from 'vue'
 * import { modalPlugin } from '@/shared/plugins/modal/modal.plugin'
 * import App from './App.vue'
 *
 * const app = createApp(App)
 * app.use(modalPlugin)
 * app.mount('#app')
 */
export const modalPlugin: Plugin = {
  async install(app: App) {
    app.config.globalProperties.$modal = modal
    app.provide(ModalKey, modal)
  },
}
