import type { App } from 'vue'
import type { Router } from 'vue-router'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'
import 'virtual:vite-plugin-sentry/sentry-config'
import { env } from '@/shared/config'

export function sentryPlugin(app: App, router: Router) {
  // https://docs.sentry.io/platforms/javascript/guides/vue/configuration/options/
  Sentry.init({
    app,
    enabled: env.IS_PROD,
    dsn: env.SENTRY_DNS,
    debug: false,
    release: env.PROJECT_NAME + '@' + env.VERSION,
    environment: env.NODE_ENV,
    sampleRate: env.IS_PROD ? 0.1 : 1,
    maxBreadcrumbs: 300,
    attachStacktrace: true,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['localhost', env.HOST_NAME, /^\//],
      }),
    ],
    tracesSampleRate: env.IS_PROD ? 0.01 : 1,
    hooks: [], // ['mount', 'update'],
    timeout: 2000,
    trackComponents: false,
  })
}
