<script setup lang="ts">
import { ToasterInfoMessage, ToasterErrorMessage } from './messages'
import { ToasterMobile } from '../model/toaster.api'

const MAX_VISIBLE_TOASTS_ON_MOBILE = 2

const scaleToast = (idx: number) => {
  return idx === 1 ? 0.9 : 1
}

const defineToastTopPosition = (idx: number) => {
  const marginRatio = 10
  return `${idx * marginRatio}px`
}

const defineToastZIndex = (idx: number) => {
  const zIndexRatio = 10
  return 100 - idx * zIndexRatio
}
</script>
<template>
  <TransitionGroup
    name="list"
    tag="ul"
    class="fixed left-0 top-16 z-50 flex w-full items-start justify-center"
  >
    <div
      v-for="(toast, idx) in ToasterMobile.toasts"
      :key="toast.id"
      class="absolute w-[90%] cursor-pointer"
      :class="`toaster-toast-${idx}`"
      :style="{
        scale: scaleToast(idx),
        marginTop: defineToastTopPosition(idx),
        zIndex: defineToastZIndex(idx),
      }"
    >
      <component
        v-if="idx < MAX_VISIBLE_TOASTS_ON_MOBILE"
        ref="target"
        class="overlay toast-shadow absolute w-full select-none"
        :title="toast.title"
        :icon="toast.icon"
        :avatar-path="toast.avatar"
        :body="toast.message"
        :is="toast.type === 'info' ? ToasterInfoMessage : ToasterErrorMessage"
      />
    </div>
  </TransitionGroup>
</template>

<style scoped lang="scss">
.toast-shadow {
  box-shadow: 0px 4px 28px 0px #0000008c;
}

.list-move, /* apply transition to moving elements */
.list-enter-active,
.list-leave-active {
  transition: all 0.65s ease;
}

.list-enter-from {
  opacity: 0;
  transform: translateY(-30px);
}

.list-leave-to {
  opacity: 0;
}

/* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
.list-leave-active {
  position: absolute;
}
</style>
