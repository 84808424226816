import type {
  IEventBus,
  IEventBusSubscribes,
  EventBusSubscribeMap,
} from './eventBus.types'

const subscribers: IEventBusSubscribes = {}

export class EventBus implements IEventBus {
  protected subscribers

  constructor() {
    this.subscribers = subscribers
  }

  protected nextId(): Symbol {
    return Symbol('')
  }

  protected subscriberGet(name: string): EventBusSubscribeMap {
    let subscriber = this.subscribers[name]
    if (subscriber) return subscriber
    subscriber = new Map()
    this.subscribers[name] = subscriber
    return subscriber
  }

  public on(name: string, callback: Function): void {
    const subscriber = this.subscriberGet(name)
    const id = this.nextId()
    subscriber.set(id, callback)
  }

  public off(name: string, callback?: Function): void {
    if (!this.subscribers[name]) return
    const subscriber = this.subscriberGet(name)
    if (callback) {
      subscriber.forEach((cb, id) => {
        if (cb === callback) subscriber.delete(id)
      })
    } else {
      delete this.subscribers[name]
    }
  }

  public one(name: string, callback?: Function): void {
    const subscriber = this.subscriberGet(name)
    const id = this.nextId()
    const callbackOne = () => {
      this.off(name, callbackOne)
      // TODO пофіксити
      // eslint-disable-next-line prefer-rest-params
      callback?.apply(arguments)
    }
    subscriber.set(id, callbackOne)
  }

  public emit<T>(name: string, payload?: T): void {
    if (!this.subscribers[name]) return
    const subscriber = this.subscriberGet(name)
    subscriber.forEach((cb) => cb(payload))
  }

  public nameGenerate(
    context: string,
    target: string,
    command: string
  ): string {
    return `${context}:${target}:${command}`
  }
}

export const eventBus = new EventBus()
