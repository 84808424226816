import type { App, Plugin } from 'vue'
import type { Router } from 'vue-router'
import { analytics } from '@/shared/plugins/analytics'
import { HotjarClient } from './hotjarClient.service'
import { HotjarAnalytics } from './hotjarAnalytics.service'

type HotjarOptions = {
  router: Router
}

export const hotjarPlugin: Plugin = {
  install(app: App, options: HotjarOptions) {
    const hotjarClient = new HotjarClient()
    const initCallback = () => {
      options.router.afterEach((to) => {
        hotjarClient.instance.stateChange(to.fullPath)
      })
    }
    const hotjarAnalytics = new HotjarAnalytics(hotjarClient, initCallback)
    analytics.adapterSet(hotjarAnalytics)
  },
}
