import type {
  IAnalyticsAdapter,
  AnalyticUserProps,
  AnalyticEventData,
  AnalyticEventOptions,
  AnalyticAuthMethod,
} from '@/shared/plugins/analytics'
import type { AmplitudeClient } from './amplitudeClient.service'

export class AmplitudeAnalytics implements IAnalyticsAdapter {
  protected _windowSeq: number = 0
  protected _userId?: string
  public windowId: string = ''
  public sessionId: string = ''
  public deviceId: string = ''
  public showLog: boolean = false

  constructor(protected client: AmplitudeClient) {}

  public async init() {
    this.client.init()
    if (this.deviceId) this.client.instance.setDeviceId(this.deviceId)
    return Promise.resolve()
  }

  public userId(userId: string) {
    this._userId = userId
    if (!this.client.isInit) return
    this.client.instance.setUserId(userId)
  }

  public userProps(props: AnalyticUserProps) {
    if (!this.client.isInit) return
    this.client.instance.setUserProperties(props)
  }

  public event(
    event: string,
    props?: AnalyticEventData,
    options?: AnalyticEventOptions
  ): void {
    if (!this.client.isInit || options?.amplitudeDisabled) return
    const amplitudeProps = Object.assign({}, props, {
      window: this.windowId,
      windowSeq: this._windowSeq++,
    })
    if (this.showLog) {
      console.log('Amplitude event: %s', event, amplitudeProps)
    }
    this.client.instance.logEvent(event, amplitudeProps)
  }

  public login(method: AnalyticAuthMethod) {
    if (!this.client.isInit) return
    this.client.instance.setUserProperties({
      login_source: method,
    })
  }

  public signUp(method: AnalyticAuthMethod) {
    if (!this.client.isInit) return
    this.client.instance.setUserProperties({
      reg_source: method,
    })
  }
}
