import type { App, Component, Plugin } from 'vue'

import type { IToasterPlugin } from '../types'
import { ToasterPluginDesktop, ToasterPluginMobile } from '../ui'
import { ToasterKey } from '../config'
import { ToasterDesktop, ToasterMobile } from './toaster.api'

export const toasterPlugin: Plugin = {
  install(app: App) {
    const isDesktop = window.innerWidth >= 1280
    const Toaster = isDesktop ? ToasterDesktop : ToasterMobile

    const toasterPluginComponent = isDesktop
      ? ToasterPluginDesktop
      : ToasterPluginMobile

    const ToasterPublic: IToasterPlugin = {
      info: Toaster.info,
      error: Toaster.error,
    }
    Object.setPrototypeOf(ToasterPublic, Toaster)

    app.component('ToasterList', toasterPluginComponent as any as Component)
    app.config.globalProperties.$toaster = ToasterPublic
    app.provide(ToasterKey, ToasterPublic)
  },
}
