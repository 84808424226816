import type {
  IAnalyticsService,
  IAnalyticsAdapter,
  AnalyticAuthMethod,
  AnalyticUserProps,
  AnalyticEventData,
  AnalyticEventOptions,
} from './analytics.types'

export class AnalyticsService implements IAnalyticsService {
  protected _isInitStarted = false
  protected _adapters = new Set<IAnalyticsAdapter>()
  // protected _windowGoogleSeq: number = 0

  constructor(
    public readonly windowId: string,
    public readonly sessionId: string,
    public readonly deviceId: string,
    public readonly showLog: boolean
  ) {}

  adapterSet(adapter: IAnalyticsAdapter) {
    adapter.windowId = this.windowId
    adapter.sessionId = this.sessionId
    adapter.deviceId = this.deviceId
    adapter.showLog = this.showLog
    this._adapters.add(adapter)
  }

  public async init() {
    if (this._isInitStarted) return Promise.reject() // TODO можливо зробити помилку
    this._isInitStarted = true
    if (this.showLog) console.log('AnalyticsService: init')
    const promises: Promise<void>[] = []
    this._adapters.forEach((adapter) => promises.push(adapter.init()))
    await Promise.all(promises)
  }

  public userId(userId: string) {
    if (this.showLog) console.log('AnalyticsService: userId = %s', userId)
    this._adapters.forEach((adapter) => adapter.userId(userId))
  }

  public userPropsGet(
    props: Partial<AnalyticUserProps> = {}
  ): AnalyticUserProps {
    return {
      ...props,
    }
  }

  public userProps(props: AnalyticUserProps) {
    if (this.showLog) {
      console.group('AnalyticsService: userProp')
      Object.keys(props).forEach((name: string) =>
        console.log('%s: %s', name, props[name as keyof AnalyticUserProps])
      )
      console.groupEnd()
    }
    this._adapters.forEach((adapter) => adapter.userProps(props))
  }

  public event(
    event: string,
    props?: AnalyticEventData,
    options?: AnalyticEventOptions
  ): void {
    if (this.showLog) console.group('AnalyticsService: ' + event)
    this._adapters.forEach((adapter) => adapter.event(event, props, options))
    if (this.showLog) console.groupEnd()
  }

  public login(method: AnalyticAuthMethod) {
    if (this.showLog) console.log('AnalyticsService: login = %s', method)
    this._adapters.forEach((adapter) => adapter.login(method))
  }

  public signUp(method: AnalyticAuthMethod) {
    if (this.showLog) console.log('AnalyticsService: signUp = %s', method)
    this._adapters.forEach((adapter) => adapter.signUp(method))
  }
}
