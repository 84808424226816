import type { App, Plugin } from 'vue'
import { AnalyticsKey } from './analytics.config'
import { analytics } from './analytics.state'

export const analyticsPlugin: Plugin = {
  async install(app: App) {
    app.config.globalProperties.$analytics = analytics
    app.provide(AnalyticsKey, analytics)
    analytics.init()
  },
}
