type CookiesGetAllItem = {
  name: string
  value: string
}
class Cookies {
  public set(name: string, value: string, days: number): void {
    let expires = ''
    const date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    expires = '; expires=' + date.toUTCString()
    document.cookie = name + '=' + (value || '') + expires + '; path=/'
  }

  public getAll(): CookiesGetAllItem[] {
    const ca = document.cookie.split(';')
    const props = ca.map((val) => {
      const caArr = val.split('=')
      const name = caArr[0].replace(/^\s+/gi, '')
      return {
        name,
        value: caArr[1],
      }
    })
    return props
  }

  public get(name: string): string | undefined {
    const nameEQ = name + '='
    const ca = document.cookie.split(';')
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) === ' ') c = c.substring(1, c.length)
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
    }
    return undefined
  }

  public remove(name: string) {
    this.set(name, '', -100)
  }
}

export const cookies = new Cookies()
